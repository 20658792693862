import * as React from 'react';

import { FunctionComponent } from 'react';
import RefferalLoggedIn from '../components/Refferal/RefferalLoggedIn';
import RefferalNotLoggedIn from '../components/Refferal/RefferalNotLoggedIn';
import { SEO } from '../components/Base/Seo';
import useAuth from '../components/useAuth';

const ReferralProgram: FunctionComponent = () => {
	const { loggedIn } = useAuth();

	return (
		<>
			{loggedIn ? (
				<RefferalLoggedIn></RefferalLoggedIn>
			) : (
				<RefferalNotLoggedIn></RefferalNotLoggedIn>
			)}
		</>
	);
};

export const Head = () => (
	<SEO
		title='Referral Program | Doctor Medica Medical Supply Store'
		description='Learn how you can earn $300 account credit just by referring another doctor to Doctor Medica. Help them save on medical supplies while saving more yourself.'
	/>
);

export default ReferralProgram;
