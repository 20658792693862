import { Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";

export interface FeatureProps {
  title: any;
  icon: any;
  direction?: any;
  align?: any;
}

export const Feature = ({ title, icon, direction, align }: FeatureProps) => {
  return (
    <Stack spacing={4} alignItems={align} flexDirection={direction}>
      <Flex
        w={10}
        h={10}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"12px"}
        bg={"#D28C49"}
        mb={1}
        gap={0}
      >
        {icon}
      </Flex>
      <Text fontWeight={400} fontSize="md" color="#18181B" lineHeight="150%">
        {title}
      </Text>
    </Stack>
  );
};
