import {
	Box,
	Button,
	Flex,
	HStack,
	Heading,
	Icon,
	Link,
	SimpleGrid,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react';
import { Coin, Target, UserCircle } from 'tabler-icons-react';
import React, { FunctionComponent } from 'react';

import { Feature } from './Feature';
import HeroContainer from '../Containers/HeroContainer';
import Layout from '../Base/Layout';

const RefferalNotLoggedIn: FunctionComponent = () => {
	return (
		<Layout>
			<HeroContainer
				height='460px'
				backgroundPosition='right'
				is404={false}
				heroTitle="Maximize Benefits Through Doctor Medica's Referral Program"
				heroSubTitle="Leverage Doctor Medica's Referral program to enhance savings and support colleagues in the medical and aesthetic fields."
				heroImage='https://shop.doctormedica.co/wp-content/uploads/2023/12/dm-referal-hero.webp'
				arrowImage='/remixiconslinesystemarrowdownsline2.svg'
				// button
				// buttonHref='https://www.doctormedica.co/sign-in'
				// buttonText='Log In And Refer Your Colleague'
			/>
			<Box
				my={{ base: 10, md: 20 }}
				mx={{ base: 4, md: 'auto' }}
				id='anchorlink'
				maxW='1280px'
				py={{ base: 6, md: 12 }}
				px={{ base: 4, md: 14 }}
				borderRadius='16px'
				bg='#F8F1F0'>
				<VStack alignItems='flex-start'>
					<HStack
						flexFlow={{ base: 'column', lg: 'row' }}
						gap='40px'
						alignItems='center'>
						<Box w='100%'>
							<Box
								bg='#B97230'
								width='24px'
								height='2px'></Box>
							<Text
								textTransform='capitalize'
								color='#120B0C'
								fontSize={{ base: '30px', md: '36px' }}
								fontWeight='400'
								pt='20px'>
								The Program Offers
							</Text>
						</Box>
					</HStack>
					<Box>
						<SimpleGrid
							py={10}
							columns={{ base: 1, md: 3 }}
							spacing={{ base: 5, md: 10 }}>
							<Feature
								icon={<Coin />}
								title={'$350* account credit for each successful referral.'}
								align='flex-start'
								direction='column'
							/>
							<Feature
								icon={<UserCircle />}
								title={'Additional $50 for every fifth successful referral.'}
								align='flex-start'
								direction='column'
							/>
							<Feature
								icon={<Target />}
								title={'Your referral will receive a $250* account credit.'}
								align='flex-start'
								direction='column'
							/>
						</SimpleGrid>

						<Text
							pb={10}
							color='#120B0C'
							fontSize='sm'
							fontWeight='400'
							lineHeight='150%'>
							There is no limit on the number of referrals you can make. The
							referral benefit is activated once the referee completes $2000 in
							successful orders.
						</Text>

						<Stack flexDir={{ base: 'column', md: 'row' }}>
							<Link
								href='/sign-in'
								_hover={{ textDecoration: 'none' }}>
								<Button
									border='0.4px solid #120B0C'
									borderRadius='48px'
									bg='transparent'
									color='#120B0C'
									fontSize='sm'
									fontWeight={700}
									lineHeight='117%'
									textTransform={'uppercase'}
									w={{ base: '100%', md: 'auto' }}
									_hover={{
										bg: 'black',
										color: 'white',
										border: '0.4px solid #fff',
									}}>
									Log in to Refer
								</Button>
							</Link>
							<HStack justifyContent='center'>
								<Text
									fontWeight={400}
									fontSize='sm'
									color='#262626'>
									Don’t have an account?{' '}
								</Text>
								<Link
									color='#D28C49'
									href='/registration'
									fontWeight={700}>
									Register here.
								</Link>
							</HStack>
						</Stack>
					</Box>
				</VStack>
			</Box>
		</Layout>
	);
};

export default RefferalNotLoggedIn;
